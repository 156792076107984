<script>
	import Vue from 'vue';
	import GlobalVue from '../helper/Global.vue'
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';
	// https://www.npmjs.com/package/vue-carousel#development
	import { Carousel, Slide } from 'vue-carousel';

	export default {
		extends: BaseVue,
		components:{
			Carousel,Slide
		},
		data() {
			return {
				data_proses:{},
				indexSlider1:0,
				indexSlider2:0,
				sliderDuration:0
			}
		},
		computed:{
			breadCrumb(){
				return [{
					name: 'Home',
					link: { name: 'index' }
				},{
					name: this.web.lbl_the_proses,
					link: { name: 'CDProses' }
				}]
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => {
				setTimeout(() => {
					$(".VueCarousel-slide").addClass("VueCarousel-slide-active");
					$(".VueCarousel-dot-container button").append("<i class='icon-line-target'></i><span></span>");
					var caroLen = $("#mainCarousel .VueCarousel-dot-container button").length;
					$("#mainCarousel .VueCarousel-dot-container button:not(:last-child) span").css("height", 446/caroLen-24+"px");
				}, 600);
				AOS.init()
			})

			this.getProses()
			this.getMrSetting()
		},
		methods: {
			getProses() {
				this.$root.topProgress.done()
				Gen.apirest("/cape-proses",{},(err,resp)=>{
					if(err) console.log(err)
					this.data_proses = resp.data
				})
			},
			getMrSetting() {
				this.$root.topProgress.done()
				Gen.apirest("/master-setting",{id:12},(err,resp)=>{
					if(err) console.log(err)
					console.log(resp)
					this.sliderDuration = parseInt(resp.data.value)*1000
				})
			},
			onChangeCorousel(x) {
				this.$set(this, 'indexSlider2',x)
			},
			onChangeCorouse2(x) {
				this.$set(this, 'indexSlider1',x)
			},
		},
		watch: {}
	};
</script>
<template>
	<div id="content">
		<section class="section notopmargin nobottommargin wall-bg">
			<div class="container">
				<BreadCrumb :data="breadCrumb"></BreadCrumb>
				<div class="heading-title">
					<h4>{{web.lbl_cape_discovery}}</h4>
					<h2>{{web.lbl_the_proses}}</h2>
				</div>
				<div class="row justify-content-center">
					<div class="col-md-9 col-lg-6 order-1 order-lg-0">
						 <carousel @page-change="onChangeCorousel" v-model="indexSlider1" :per-page="1" :mouse-drag="true" :loop="true" :paginationEnabled="false">
							<slide :data-index="k" :data-name="v.title" class="process-item" v-for="(v,k) in data_proses" :key="k">
								<h4 class="pd-title">{{v.title}}</h4>
								<div v-html="v.description"></div>
							</slide>
						</carousel>
					</div>
					<div class="col-md-9 col-lg-6">
						<carousel @page-change="onChangeCorouse2" v-model="indexSlider2" :per-page="1" :mouse-drag="true" :autoplay="true" :autoplayDirection="'forward'" :loop="true" :autoplayTimeout="(sliderDuration||5000)" id="mainCarousel" :paginationSize="20" :paginationPadding="0" paginationActiveColor="rgba(0,0,0,0)">
							<slide class="process-item" v-for="(v,k) in data_proses" :key="k">
								<img :src="uploader(v.image)" :alt="v.title">
							</slide>
						</carousel>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>